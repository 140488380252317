import React, { useEffect, useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";
// import LocomotiveScroll from "locomotive-scroll";
import { gsap } from "@helpers/gsap";

import Header from "@components/Header";
import Footer from "@components/Footer";
import Seo from "@components/Seo";

const AboutPage = () => {
  const DOMref = useRef();
  // useEffect(() => {
  //   const scroll = new LocomotiveScroll({
  //     el: document.querySelector("[data-scroll-container]"),
  //     smooth: true,
  //   });
  // }, []);

  return (
    <>
      <Seo title="About us" />
      <main className="aboutPage" data-scroll-container data-scroll-section>
        <div className="aboutPage__header">
          <Header />
        </div>
        <section className="aboutPage__content" id="section">
          <div className="aboutPage__text-content" data-scroll>
            <h1 className="aboutPage__title">us</h1>
            <div className="aboutPage__desc-blocks">
              <h2>How we started</h2>
              <p>
                With the zest to provide better services in the real estate and
                construction industry, Real Projects started about four years
                ago.
              </p>
              <p>
                By providing quality real estate across all strata of society as
                against mediocre services that have become the industry
                standard, we have achieved this goal and started a renaissance
                of the real estate industry.
              </p>
              <StaticImage
                src="../assets/images/about-img-1.jpg"
                alt="a nice apartment"
              />
            </div>
            <div className="aboutPage__desc-blocks">
              <h2>What we believe</h2>
              <p>
                Our credence at Real Projects is that quality can be provided
                for at the best market value and we deliver this in an
                unparalleled excellent experience.
              </p>
              <StaticImage
                src="../assets/images/about-img-2.jpg"
                alt="a nice apartment"
              />
            </div>
            <div className="aboutPage__desc-blocks">
              <h2>Who we are</h2>
              <p>
                Real projects is assiduously managed by a team of ardent
                industry players with a vision to create a renaissance real
                estate company to bring about an overhaul and improvement of the
                standard in real estate.
              </p>

              <StaticImage
                src="../assets/images/about-img-3.jpg"
                alt="a nice apartment"
              />
            </div>
            <div className="aboutPage__desc-blocks">
              <h2>Who we serve</h2>
              <p>
                With 25 completed projects in four years of business and more in
                the works, we offer value across every strata of the society
                serving the Lekki, Victoria Island, Ikoyi, Mushin, Ajegunle, and
                Ikeja environs of Lagos State and expanding.
              </p>
            </div>
          </div>
          <div className="aboutPage__image-slide" data-scroll>
            <StaticImage
              src="../assets/images/about-img-1.jpg"
              alt="a nice apartment"
            />
            <StaticImage
              src="../assets/images/about-img-2.jpg"
              alt="a nice apartment"
            />
            <StaticImage
              src="../assets/images/about-img-3.jpg"
              alt="a nice apartment"
            />
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
};

export default AboutPage;
