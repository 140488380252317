import React from "react";
import classNames from "classnames/bind";
import { Link } from "gatsby";
import { useStaticAssets } from "@hooks/queries";

import * as style from "./Footer.module.scss";

const cx = classNames.bind(style);
const navLinks = [
  {
    text: "about us",
    href: "/about",
    current: false,
  },
  {
    text: "projects",
    href: "#",
    current: false,
  },
  // {
  //   text: "blog",
  //   href: "#",
  //   current: false,
  // },
  {
    text: "contact",
    href: "/contact",
    current: false,
  },
  // {
  //   text: "careers",
  //   href: "#",
  //   current: false,
  // },
];

const Footer = () => {
  const { logoGrey } = useStaticAssets();

  return (
    <div className={style.container}>
      <Link className={style.logo} to="/">
        <img src={logoGrey.publicURL} alt="logo" />
      </Link>
      <ul className={style.nav}>
        {navLinks.map((link) => (
          <li key={link.text}>
            <Link to={link.href} className="link-underline-hover">
              {link.text}
            </Link>
          </li>
        ))}
      </ul>
      <ul className={style.socials}>
        <li>
          <a href="#" className="link-underline-hover">
            Facebook.
          </a>
        </li>
        <li>
          <a href="#" className="link-underline-hover">
            Instagram
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
